module.exports = [{
      plugin: require('../../../node_modules/.pnpm/@chakra-ui+gatsby-plugin@3.1.3_@chakra-ui+react@2.10.5_@emotion+react@11.14.0_@types+react@19_bfjapuv63ba62yipwstlsm6awe/node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__esbuild@0.24.2_dyoyb7ur5ei34adztefktuy4sq/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Grendel Consulting","short_name":"Grendel Consulting","icon":"./src/images/icon.png","icon_options":{"purpose":"any maskable"},"background_color":"#FFFFFF","theme_color":"#2C5282","start_url":"/","display":"standalone","lang":"en","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f6549db70af25725901605e093225cfa"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@8.57.1__esbuild@0.24.2_react-dom@18.3.1_react@18.3.1_tq3pjrkgsak2bugebmav2usl7i/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
