exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-page-tsx-content-file-path-src-content-accessibility-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/codebuild/output/src3673648104/src/codestar-connections.eu-west-1.amazonaws.com/git-http/847790937489/eu-west-1/05eeff7a-bfe3-4635-a253-6ad4a57ef236/grendel-consulting/www.grendel-consulting.com/frontend/website/src/content/accessibility.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-content-accessibility-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-content-colophon-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/codebuild/output/src3673648104/src/codestar-connections.eu-west-1.amazonaws.com/git-http/847790937489/eu-west-1/05eeff7a-bfe3-4635-a253-6ad4a57ef236/grendel-consulting/www.grendel-consulting.com/frontend/website/src/content/colophon.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-content-colophon-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-content-cookies-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/codebuild/output/src3673648104/src/codestar-connections.eu-west-1.amazonaws.com/git-http/847790937489/eu-west-1/05eeff7a-bfe3-4635-a253-6ad4a57ef236/grendel-consulting/www.grendel-consulting.com/frontend/website/src/content/cookies.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-content-cookies-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-content-experiments-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/codebuild/output/src3673648104/src/codestar-connections.eu-west-1.amazonaws.com/git-http/847790937489/eu-west-1/05eeff7a-bfe3-4635-a253-6ad4a57ef236/grendel-consulting/www.grendel-consulting.com/frontend/website/src/content/experiments.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-content-experiments-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-content-index-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/codebuild/output/src3673648104/src/codestar-connections.eu-west-1.amazonaws.com/git-http/847790937489/eu-west-1/05eeff7a-bfe3-4635-a253-6ad4a57ef236/grendel-consulting/www.grendel-consulting.com/frontend/website/src/content/index.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-content-index-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-content-privacy-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/codebuild/output/src3673648104/src/codestar-connections.eu-west-1.amazonaws.com/git-http/847790937489/eu-west-1/05eeff7a-bfe3-4635-a253-6ad4a57ef236/grendel-consulting/www.grendel-consulting.com/frontend/website/src/content/privacy.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-content-privacy-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-content-security-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/codebuild/output/src3673648104/src/codestar-connections.eu-west-1.amazonaws.com/git-http/847790937489/eu-west-1/05eeff7a-bfe3-4635-a253-6ad4a57ef236/grendel-consulting/www.grendel-consulting.com/frontend/website/src/content/security.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-content-security-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-content-services-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/codebuild/output/src3673648104/src/codestar-connections.eu-west-1.amazonaws.com/git-http/847790937489/eu-west-1/05eeff7a-bfe3-4635-a253-6ad4a57ef236/grendel-consulting/www.grendel-consulting.com/frontend/website/src/content/services.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-content-services-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-content-sustainability-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/codebuild/output/src3673648104/src/codestar-connections.eu-west-1.amazonaws.com/git-http/847790937489/eu-west-1/05eeff7a-bfe3-4635-a253-6ad4a57ef236/grendel-consulting/www.grendel-consulting.com/frontend/website/src/content/sustainability.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-content-sustainability-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-content-terms-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/codebuild/output/src3673648104/src/codestar-connections.eu-west-1.amazonaws.com/git-http/847790937489/eu-west-1/05eeff7a-bfe3-4635-a253-6ad4a57ef236/grendel-consulting/www.grendel-consulting.com/frontend/website/src/content/terms.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-content-terms-mdx" */),
  "component---src-templates-page-tsx-content-file-path-src-content-ways-of-working-mdx": () => import("./../../../src/templates/page.tsx?__contentFilePath=/codebuild/output/src3673648104/src/codestar-connections.eu-west-1.amazonaws.com/git-http/847790937489/eu-west-1/05eeff7a-bfe3-4635-a253-6ad4a57ef236/grendel-consulting/www.grendel-consulting.com/frontend/website/src/content/ways-of-working.mdx" /* webpackChunkName: "component---src-templates-page-tsx-content-file-path-src-content-ways-of-working-mdx" */)
}

